import {tryToDate, getDataByCodePostal, newInput, cssVal, visibility, isEmpty} from "../../helpers";
import {def} from "../../dictionnaire";


export const sarl = {
    title: "SARL Société à responsabilité limitée",
    texte: "Suivant {{acteGroup.natureActe}}, en date du {{acteGroup.dateActe}}, à {{acteGroup.lieuActe}},[[ reçu par Me {{acteGroup.recuPar}}]] a été constituée la société suivante : <strong>Forme</strong> : {{societyGroup.societeFormeJuridique}} <strong>Dénomination : {{societyGroup.societeDenomination}}</strong>[[ <strong>Sigle</strong> : {{societyGroup.sigle}}]]. <strong>Siège</strong : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}. <strong>Objet</strong> : {{societyGroup.objet}}. <strong>Durée</strong> : {{societyGroup.duree}} ans à compter de <strong>l'immatriculation au Rcs</strong> {{siegeSocialGroup.greffe}}. <strong>Capital</strong> {{societyGroup.typeCapital}}. <strong>Gérance</strong> : {{gerancesGroup.gerances}}. [[{{cogerancesGroup.gerances}}.]]",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "acteGroup.dateActe":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            case "societyGroup.typeCapital":
                if(varText['societyGroup.typeCapital'] === 'variable') {
                    tmpText = `variable, initial : ${cssVal(varText["societyGroup.societeCapitalInitial"])} €, minimum : ${cssVal(varText["societyGroup.capitalMinimum"])} €`;
                } else {
                    tmpText = ` : ${cssVal(varText["societyGroup.societeCapital"])} €`
                }
                break;
            case "gerancesGroup.gerances":
                tmpText = ` ${cssVal(varText["gerancesGroup.prenom"])} ${cssVal(varText["gerancesGroup.nom"])} ${cssVal(varText["gerancesGroup.adresse"])} ${cssVal(varText["gerancesGroup.adresseSuite"])} ${cssVal(varText["gerancesGroup.codePostal"])} ${cssVal(varText["gerancesGroup.ville"])}`;
                break;
            case "cogerancesGroup.gerances":
                tmpText = ` ${cssVal(varText["cogerancesGroup.prenom"])} ${cssVal(varText["cogerancesGroup.nom"])} ${cssVal(varText["cogerancesGroup.adresse"])} ${cssVal(varText["cogerancesGroup.adresseSuite"])} ${cssVal(varText["cogerancesGroup.codePostal"])} ${cssVal(varText["cogerancesGroup.ville"])}`;
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        siegeSocialGroup: [{}],
        gerancesGroup: [{}],
        cogerancesGroup: [{}],
    },
    schema: [
        {
            //0
            name: "acteGroup",
            label: "Les statuts",
            type: "group",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change: (val) => {
                            val = val.target.value;
                            sarl.schema[0].children[3] = visibility(sarl.schema[0].children[3], val, "acte authentique");
                        }
                    }
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "societyGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.formeSocieteSarl, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.objet, '_required'),
                newInput(def.duree, '_required'),
                newInput(def.typeCapital, '_required', {
                    on: {
                        input: (val) => {
                            sarl.schema[1].children[6] = visibility(sarl.schema[1].children[6], val, 'fixe', false);
                            sarl.schema[1].children[7] = visibility(sarl.schema[1].children[7], val, 'variable');
                            sarl.schema[1].children[8] = visibility(sarl.schema[1].children[8], val, "variable");
                        }
                    }
                }),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
                newInput(def.capitalInitial, '_required', {name: 'societeCapitalInitial'}, 'hidden'),
                newInput(def.capitalMinimum, '_required', {}, 'hidden'),
            ]
        },
        {
            //2
            name: "siegeSocialGroup",
            label: "Siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);

                                if(Object.keys(data).length === 0) {
                                    return;
                                }

                                sarl.schema[2].children[3].options = data.options;
                                sarl.model.siegeSocialGroup[0].societeRcsVille = data.greffe;
                                if(data.ville_slug !== null) {
                                    sarl.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                                }
                            }
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'disabled'),
            ]
        },
        {
            //3
            name: "gerancesGroup",
            label: "La gérance",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);

                                if(isEmpty(data)) {
                                    return;
                                }

                                sarl.schema[3].children[5].options = data.options;
                                if (data.ville_slug !== null) {
                                    sarl.model.gerancesGroup[0].ville = data.ville_slug;
                                }
                            }
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //4
            label: "Ajouter la Co-gérance",
            type: "button",
            on: {
                click: () => {
                    sarl.schema[4].type = "hidden";
                    sarl.schema[4].label = "";

                    sarl.schema[5].type = "group";
                    sarl.schema[5].label = "La Co-gérance";

                    sarl.schema[6].type = "button";
                    sarl.schema[6].label = "Supprimer la Co-gérance";
                }
            }
        },
        {
            //5
            name: "cogerancesGroup",
            label: "",
            type: "hidden",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length === 5) {
                                const data = await getDataByCodePostal(val);

                                if(isEmpty(data)) {
                                    return;
                                }

                                sarl.schema[5].children[5].options = data.options;
                                if (data.ville_slug !== null) {
                                    sarl.model.cogerancesGroup[0].ville = data.ville_slug;
                                }
                            }
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //6
            label: "",
            type: "hidden",
            on: {
                click: () => {
                    sarl.schema[4].type = "button";
                    sarl.schema[4].label = "Ajouter la Co-gérance";

                    sarl.schema[5].type = "hidden";
                    sarl.schema[5].label = "";

                    sarl.schema[6].type = "hidden";
                    sarl.schema[6].label = "";
                }
            }
        }
    ],
};